@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 197 52% 26%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 150.48 64.29% 61.57%;
    --primary-foreground: 0 0% 98%;
    --secondary: 0 0% 91%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 16% 93%;
    --accent-foreground: 240 16% 93%;
    --destructive: 0 72.22% 50.59%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 5% 64.9%;
    --radius: 0.5rem;

    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;

    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  html {
    @apply scroll-smooth;
  }
  body {
    @apply bg-background text-foreground;
    /* font-feature-settings: "rlig" 1, "calt" 1; */
    font-synthesis-weight: none;
    text-rendering: optimizeLegibility;
  }
}

@layer utilities {
  .step {
    counter-increment: step;
  }

  .step:before {
    @apply absolute w-9 h-9 bg-muted rounded-full font-mono font-medium text-center text-base inline-flex items-center justify-center -indent-px border-4 border-background;
    @apply ml-[-50px] mt-[-4px];
    content: counter(step);
  }

  .chunk-container {
    @apply shadow-none;
  }

  .chunk-container::after {
    content: "";
    @apply absolute -inset-4 shadow-xl rounded-xl border;
  }
}

@media (max-width: 640px) {
  .container {
    @apply px-4;
  }
}

@-moz-document url-prefix() {
  body {
    scrollbar-width: auto;
    scrollbar-color: #dbe8f0 #f0f4f5;
  }

  .gap-scrollbar {
    scrollbar-width: thin !important;
    scrollbar-color: #dbe8f0 #fff !important;
    padding-right: 4px;
  }

  .small-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #dbe8f0 #f0f4f5;
  }
}

/* Scroll WebKit  */
::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
}

::-webkit-scrollbar-thumb {
  background-color: #dbe8f0 !important;
  border-radius: 10px !important;
}

.gap-scrollbar::-webkit-scrollbar {
  width: 16px !important;
  height: 16px !important;
}

.gap-scrollbar::-webkit-scrollbar-thumb {
  background-color: #dbe8f0 !important;
  border-radius: 10px !important;
  border: 4px solid #fff !important;
}

.small-scrollbar::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}

body::-webkit-scrollbar-track {
  background: #f0f4f5 !important;
}